<!-- 领券中心 -->
<template>
  <div class="discountcenter">
    <div class="centerdis">
      <crumbs class='crumbs'  :location='location' @exportCls='exportCls' ></crumbs>
      <div class="banner">
        <img src="../assets/img/discountcenter/banner.png">
      </div>
      <usercentertitle title='为你推荐好券' :types='3'>
        <div slot="rtcnt" class="rt">
          <button @click='myDiscount'><svg class='lts'><use xlink:href="#iconyouhuiquan"></use></svg>我的优惠券<svg class='rts'><use xlink:href="#iconziyuan4"></use></svg></button>
        </div>
      </usercentertitle>
      <div class="discountlist" v-if='discountdata&&discountdata.length>0'>
        <discountcenteritem class='discountcenteritem' v-for='item,index in discountdata' @exportgets='exportgets' :popData='item' :key='index' ></discountcenteritem>
      </div>
    </div>
  </div>
</template>
<script>
import crumbs from '@/components/crumbs/crumbs.vue';//面包屑导航
import usercentertitle from '@/components/usercenter-title/usercenter-title.vue';//内容标题
import discountcenteritem from '@/components/discountcenteritem/discountcenteritem.vue';//优惠券
export default {
  name: 'discountcenter',
  components: {
    crumbs,
    usercentertitle,
    discountcenteritem
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'领券中心-微微定',
      location:[{Name:'首页'},{Name:'领券中心',Id:1}],//面包屑
      discountdata:null,//优惠券列表
    }
  },
  props: {
    msg: String
  },
  methods:{
    myDiscount(){
      // 跳转优惠券
      this._router({then:()=>{
        this.$router.push({path: "/userdiscount.html"})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/userdiscount.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    exportCls(v){
      if(!v.Id){
        this._router({then:()=>{
          this.$router.push({path: "/index.html"})
        }})
      }
    },
    exportgets(){
      // 领券之后刷新列表
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainVoucherCenterList',this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.discountdata = res.data.Data.list
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:'/discountcenter.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        // 请求领券中心接口
        this.exportgets()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.discountcenter {
  background: #fff;
  .centerdis {
    width: 1200px;margin:0 auto;padding-bottom:20px;
    .crumbs {
      margin-bottom:5px;padding-top:5px;
    }
    .banner {
      margin-bottom:10px;
      img {
        display: block;width: 100%;
      }
    }
    .discountlist {
      zoom:1;margin-top:10px;
      .discountcenteritem {
        float:left;margin-right: 30px;margin-bottom:30px;
        &:nth-child(3n+3){
          margin-right: 0px;
        }
      }
      &:after {
        content: '';display: block;clear:both;
      }
    }
    .rt {
      position: absolute;top:0px;right: 0px;
      button {
        width: 128px;height: 30px;background: #fd6874;color:#fff;font-size: 12px;border-radius: 20px;
        svg {
          fill: #fff;
        }
        .lts {
          width: 15px;height: 15px;vertical-align: -3px;margin-right: 5px;
        }
        .rts {
          width: 10px;height: 10px;margin-left:2px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
