<template>
  <div class="discountcenteritem">
    <div class="item">
<!--       <div class="tip">
        新手专享
      </div> -->
      <div class="cnt">
        <div class="lt">
          <p class="p"><span>&yen;</span>{{popData?popData.Amount:''}}</p>
          <p class="p1">{{popData&&popData.ConsumptionAmount!==0?'满'+popData.ConsumptionAmount+'使用':'无门槛'}}</p>
        </div>
        <div class="rt">
          <p class="p">{{popData?popData.VoucherName:''}}</p>
          <p class="p1">{{popData?popData.UseStartDate:'' | setTimes}}</p>
          <p class="p1">{{popData?popData.UseEndDate:'' | setTimes}}</p>
        </div>
        <div class="btms">
          <div class='btmsbg'>
          </div>
        </div>
      </div>
      <div class="btn">
        <button v-if='popData&&(!popData.CustomerId||popData.DrawNum>=2)?true:false' @click="getDiscount(popData)">领</button>
        <svg v-if='popData&&(popData.CustomerId&&popData.DrawNum<2)?true:false'><use xlink:href="#iconyilingqu"></use></svg>
      </div>
    </div>
    <el-dialog
        :show-close='false'
        :visible.sync="dialogVisible"
        width="300px"
        custom-class='popGets'
        >
        <div class="popBox">
          <h6 class="h6">恭喜您，领取成功！</h6>
          <p class="p">可以前往我的优惠券列表查看</p>
          <div class="popbtn">
            <button @click='usediscount'>立即使用</button>
            <button @click='myDiscount'>我的优惠券</button>
          </div>
          <p class="p1"><span>{{setTime}}</span>秒后自动关闭</p>
          <button class="closepop" @click="closePop"><svg><use xlink:href="#iconziyuan1"></use></svg></button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'discountcenteritem',
  props: {
    popData: Object
  },
  data(){
    return {
      dialogVisible:false,
      setTime:5,
      CouponId:null,
      t:0,
    }
  },
  methods:{
    usediscount(){
      // 使用优惠券
      if(this.CouponId){
        // 使用优惠券
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/UseProductVoucher?couponcodeId='+this.CouponId,this.keys())
          .then(res => {
              if(res.data.Success){
                if(res.data.Data.producttypeid===''){
                  if(this.$route.path.indexOf('/classlist')==-1){
                    if(res.data.Data.key){
                      var _searchQueryObj = {
                        key:res.data.Data.key,
                        orderBy:0,
                        OrderT:1,
                        parentId:'',
                        startPrice:0,
                        endPrice:0,
                        index:1
                      }
                    }else{
                       var _searchQueryObj = {
                          key:'',
                          orderBy:0,
                          OrderT:1,
                          parentId:'',
                          startPrice:0,
                          endPrice:0,
                          index:1
                        }
                    }
                    window.sessionStorage.setItem('searchQuery',JSON.stringify(_searchQueryObj))
                    window.sessionStorage.setItem('localseo',window.sessionStorage.getItem('classlistseo'))
                    this._open({path:'/classlist',query:{keys:this.searchTxt+'.html'}})
                  }
                }else{
                  this._router({then:()=>{
                    // this.$router.push({path:'predetail',query:{id::res.data.Data.producttypeid,idcx:'',detailType:1}})
                    this._open({path:'/predetail',query:{idcx:'',id:res.data.Data.producttypeid+'.html',}})
                  }})
                }
              }else{
                this.$MessageBox.alert(res.data.Error,{type:'error'});
              }
            }, res => {
              // this.$MessageBox.alert('系统异常',{type:'error'});
            })
        },exp:true,catch:()=>{
            var pms  = '/index.html'
              this.$router.push({path:'/accountlogin',query:{redirect:pms}})
              // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      }
    },
    myDiscount(){
      // 跳转优惠券
      this._router({then:()=>{
        this.$router.push({path: "/userdiscount.html"})
      },exp:true,catch:()=>{
        var pms  = '/userdiscount.html'
        this.$router.push({path:'/accountlogin',query:{redirect:pms}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    closePop(){
      // 关闭领券弹窗
      clearInterval(this.t)
      this.dialogVisible = false
      setTimeout(()=>{
        this.setTime=5
      },500)
    },
    getDiscount(item){
      // 领券
      this.CouponId = null
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/CollectCoupons?VoucherId='+item.VouchersID,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // if(res.data.Data.LoinState&&res.data.Data.LoinState=='fail'){
              //   this.$store.dispatch("cmtSetUser",null);//赋值
              //   this._usermet({method:'del'});//存本地
              //   this._token({method:'del'});//存本地
              //   return  this.$router.push({path:'accountlogin',query:{redirect:this.$route.fullPath}})
              // }
              if(res.data.Data.result===0){
                this.$emit('exportgets')
                this.dialogVisible = true
                this.CouponId = res.data.Data.CouponId
              }else{
                this.$MessageBox.alert(res.data.Data.message,{type:'error'});
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      },exp:true,catch:()=>{
          var pms  = '/index.html'
            this.$router.push({path:'/accountlogin',query:{redirect:pms}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
  },
  watch:{
    dialogVisible(n,o){
      if(n){
        this.t = setInterval(()=>{
          this.setTime--
          if(this.setTime===0){
            clearInterval(this.t)
            this.dialogVisible = false
            setTimeout(()=>{
              this.setTime=5
            },500)
          }
        },1000)
      }
    }
  },
  filters:{
    setTimes(v){
      return v.split('T')[0]
    }
  }
}
</script>
<style  lang="scss" scoped>
.discountcenteritem {
  .item {
    width: 380px;height: 146px;display: flex;justify-content: center;align-items: center;background: #fce6cb;border-radius: 10px;position: relative;overflow: hidden;
    .tip {
      width: 150px;height: 36px;position: absolute;background: #f1584d;z-index: 11;text-align: center;line-height: 36px;color:#fff;font-size: 12px;left:-45px;top:10px;transform: rotate(-45deg);
    }
    .cnt {
      width: 370px;height: 136px;position: relative;background: #fff;border-radius: 10px;overflow: hidden;
      box-shadow: 5px 0px 10px -4px rgba(0,0,0,0.1);
      .lt {
        float:left;position: relative;z-index: 12;margin-left:60px;width: 82px;margin-top:18px;
        .p {
          font-size: 42px;color:#f1584d;font-weight:bold;text-align: center;
          span {
            font-size: 18px;color:#f1584d;margin-right: 5px;
          }
        }
        .p1 {
          width: 82px;height: 26px;background: #f6e8bd;text-align: center;line-height: 26px;font-size: 12px;color:#c08f48;border-radius: 5px;margin:0 auto;
        }
      }
      .rt {
        float:left;width: 82px;margin-top:40px;margin-left:5px;
        .p {
          color:#c0955e;font-size: 14px;text-align: center;
        }
        .p1 {
          color:#dfc3a6;font-size: 12px;text-align: center;
        }
      }
      .btms {
        width: 100%;overflow: hidden;border-top-left-radius: 10px;border-top-right-radius: 10px;float:left;position:absolute;bottom:0px;
        .btmsbg {
          display: flex;justify-content: flex-start;
          width: 100%;
          height: 20px;
          background-image:
          radial-gradient(
            circle at 9px -1px,
            transparent 8px,
            #f3d98b 6px,
            #f3d98b 0px);
          background-size:  20px 200px;
          background-position: 0 0, 200px 0;
          background-repeat-y: no-repeat;
        }
      }
      &:after {
        content: '';display: block;position: absolute;width: 40px;height: 50px;background: #fce6cb;top:0px;left:0px;border-radius: 50%;left:-20px;top:43px;box-shadow: inset 0px 0px 8px -2px rgba(0,0,0,0.1);z-index: 9;
      }
      &:before {
        content: '券';display: block;position: absolute;z-index: 8;font-size: 80px;color:#fef3f4;font-weight: bold;top:0px;left:-5px;
      }
    }
    .btn {
      position: absolute;height: 146px;width: 150px;border-radius: 10px;top:0px;right: 0px;background: #f9506e;overflow: hidden;
      button {
        width: 75px;height: 75px;border-radius: 50%;background-image: linear-gradient(to right, #eedda2 , #fff5da);position: absolute;top:35.5px;right:10px;font-size: 32px;color:#c08f48;font-weight: bold;
      }
      svg {
        width: 75px;height: 75px;position: absolute;top:35.5px;right:10px;fill:#fff;opacity: 0.8;
      }
      &:after {
        content: '';display: block;position: absolute;width: 100px;height: 100px;border-radius: 50%;background: #fff;left:-50px;top:23px;box-shadow: inset 0px 0px 10px -2px rgba(250,79,111,1);
      }
    }
  }
  /deep/ .popGets {
    border-radius:15px;overflow: hidden;
    .el-dialog__header {
      padding:0px;
    }
    .el-dialog__body {
      padding:0px;
      .popBox {
        width: 300px;height: 330px;background: url('../../assets/popgets.png');
        .h6 {
          font-size: 18px;padding-top:200px;text-align: center;
        }
        .p {
          text-align: center;color:#999;font-size: 14px;margin-top:5px;margin-bottom:10px;
        }
        .popbtn {
          display: flex;justify-content: center;
          button {
            width: 100px;height: 30px;border-radius: 3px;background: #f44e55;color:#fff;font-size: 14px;
            &:nth-child(2) {
              border:1px solid #d2d2d2;background: none;color:#333;margin-left:24px;
            }
          }
        }
        .p1 {
          font-size:14px;color:#b1b1b1;text-align: center;margin-top:10px;
          span {
            color:#333;
          }
        }
        .closepop {
          background: none;position: absolute;top:10px;right:10px;
          svg {
            fill: #333;
          }
        }
      }
    }
  }
}
</style>
