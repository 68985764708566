<template>
  <div class="usercentertitle" :class='{types:types==1,types1:types==2,types2:types==3}'>
    {{title}}
    <slot name="rtcnt"></slot>
  </div>
</template>
<script>
export default {
  name: 'usercentertitle',
  props: {
    title: String,
    types:Number
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.usercentertitle {
  background: #fff;height: 60px;line-height: 60px;font-size: 16px;font-weight: bold;color:#333;position: relative;padding-left:30px;margin-bottom:1px;
  &:after {
    content: '';display: block;position: absolute;width: 2px;height: 20px;background: #db5570;top:20px;left:20px;
  }
}
.types {
  width: 1190px;margin:0 auto;padding-left:10px;
  &:after {
    left:0px;
  }
}
.types1 {
  color:#f54e62;
  &:after {
    background: #f54e62;
  }
}
.types2 {
  background: none;padding-left:15px;
  &:after {
    left:0px;
  }
}
</style>
